import React, { Component } from 'react'
import { ForumLayout } from '../forum'
import { navigate } from '@reach/router'
import { Card, CardHeader, CardBody, Badge, CardText, CardFooter, Button, Form, FormGroup, Col, ListGroupItemHeading, ListGroupItemText, ListGroupItem, UncontrolledAlert, Alert } from 'reactstrap'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import ReactHtmlParser from 'react-html-parser'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { handleCommentSubmit, handleGetForumComments, handleGetForumPostDetails, handlePostClose } from '../../../services/api'
import { isLoggedIn } from '../../../services/auth'
import { PulseLoader, BarLoader } from 'react-spinners'
import validator from 'validator'
import qs from 'query-string'
import ReCAPTCHA from "react-google-recaptcha";


const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false
const override = `
    margin: 0 auto;
`

export default class view extends Component {
    constructor(props) {
        super(props)

        let queryData = null
        if (props.location.search !== "") {
            queryData = qs.parse(props.location.search, { ignoreQueryPrefix: true })
        }


        let notification = false
        let notificationMessage = ""
        if (!this.props.location.state) {
            notification = false
        } else {
            notification = !this.props.location.state.notification ? false : true
            notificationMessage = !this.props.location.state.notificationMessage ? "" : this.props.location.state.notificationMessage
        }

        this.state = {
            editorState: EditorState.createEmpty(),
            name: user ? user.userData.attributes.name : "",
            username: user ? user.userData.username : "",
            email: user ? user.userData.attributes.email : "",
            id: !queryData ? null : queryData.id,
            html: [],
            notification: notification,
            notificationMessage: notificationMessage,
            postDetail: "",
            closeLoading: false,
            isLoading: true,
        }


    }


    async componentDidMount () {
        if (!this.props.location.search) {
            navigate('/support/forum/', { state: {} })
            this.forceUpdate()
        } else {
            this.setState({
                isLoading: true
            })
            let postDetail = await handleGetForumPostDetails({ id: this.state.id })
            let res = await handleGetForumComments({ id: this.state.id })

            if (postDetail.length === 0) {
                await navigate('/support/forum/', { state: { danger: true, dangerMessage: "The forum post you are trying to access is either deleted or moved." } })
                if (typeof window !== "undefined") {
                    window.location.reload()
                }
            } else {
                this.setState({
                    html: res,
                    postDetail: postDetail
                }, () => {
                    this.setState({
                        isLoading: false
                    })
                })
            }
        }
    }

    handleClose = async (event) => {
        this.setState({
            closeLoading: true
        })
        await handlePostClose(this.state)
            .then((res) => {
                navigate('/support/forum/view/?id=' + this.state.id, { state: { details: this.state.props, notification: true, notificationMessage: "Your post has been successfully closed" } })
                if (typeof window !== "undefined") {
                    window.location.reload()
                }
            })
            .catch(err => console.log(err))
    }



    // # TODO: Add the checker for empty / non-existent IDs
    render () {
        return (
            <ForumLayout location={this.props.location} fluid={false}>
                {this.state.notification ? (
                    <UncontrolledAlert color="success" >
                        {this.state.notificationMessage}
                    </UncontrolledAlert>)
                    : null}
                {this.state.isLoading ? (
                    <div className="text-center" style={{ margin: `auto`, padding: `1.5rem 0` }}>
                        {`Loading Forum Post details...`}
                        <BarLoader
                            sizeUnit={"px"}
                            width={250}
                            color={'#123abc'}
                            css={override}
                        />
                    </div>
                ) : (
                    <div>
                        <div className="clearfix pb-3">
                            <h3 className="float-left">
                                {!this.state.postDetail || this.state.postDetail[0].category ? (this.state.postDetail[0].category).toUpperCase() : null}
                            </h3>
                            {!this.state.postDetail || (this.state.postDetail[0].status === 'CLOSED' || this.state.postDetail[0].status === 'ANSWERED') ? null : this.state.postDetail[0].author_id === this.state.username ? (
                                <Button
                                    className="float-right"
                                    color={this.state.closeLoading ? 'secondary' : 'info'}
                                    disabled={this.state.closeLoading}
                                    onClick={
                                        event => {
                                            event.preventDefault()
                                            this.handleClose()
                                        }
                                    }
                                >
                                    {this.state.closeLoading ? 'Loading...' : 'Close Thread'}
                                </Button>
                            ) : null}

                        </div>

                        {/* POST DETAILS */}
                        <ViewContent data={this.state.postDetail} />
                        <br />

                        {/* POST COMMENTS */}
                        <h3>
                            Comments
                        </h3>
                        <hr />
                        <ViewComments content={this.state.html} />
                        <br />
                        {!this.state.postDetail ? null : this.state.postDetail[0].status === "OPEN" ? (
                            <div>
                                <h4>
                                    Add new comment
                                </h4>

                                {isLoggedIn() ? (
                                    <CommentForm data={this.state.postDetail} />
                                ) : (
                                    <span>
                                        Please <a href={"/signin/?referrer=" + encodeURIComponent(this.props.location.href)}>Sign In</a> to add a new comment
                                    </span>
                                )}
                            </div>
                        ) : "Thread Closed. Commenting has been disabled for this post"}
                    </div>
                )}
            </ForumLayout>
        )
    }
}

class CommentForm extends Component {
    constructor(props) {
        super(props)

        const data = props.data[0].post_id

        this.state = {
            editorState: EditorState.createEmpty(),
            name: user ? user.userData.attributes.name : "",
            username: user ? user.userData.username : "",
            email: user ? user.userData.attributes.email : "",
            id: data,
            html: [],
            title: !props.data ? null : props.data[0].title,
            isLoading: false,
            props: props.data,

            commentValid: false,
            formValid: false,

            visible: false,
            errorMessage: "",
            captcha: "",
            recaptcha: true,
        }
        this.onDismiss = this.onDismiss.bind(this)
    }


    onDismiss () {
        this.setState({
            visible: !this.state.visible
        })
    }

    isASCII (str) {
        return RegExp('^[\x00-\x7F]*$').test(str);
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }, () => {
            this.validateInput(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        });
    };


    validateInput (value) {
        let commentValid = this.state.commentValid

        commentValid = (!validator.isEmpty(value) && validator.matches(value, RegExp('^(?=.{8})')))

        this.setState({
            commentValid: commentValid
        }, () => {
            this.validateForm()
        })
    }

    captchaChange = (value) => {
        const token = value

        this.setState({
            captcha: token
        }, () => {
            this.validateForm()
        })
    }

    validateForm () {
        this.setState({
            formValid: this.state.commentValid && !!this.state.captcha
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        let strDescription = this.state.description
        this.setState({
            isLoading: true
        })

        if (this.isASCII(strDescription)) {
            await handleCommentSubmit(this.state)
                .then(async (res) => {
                    await this.setState({
                        editorState: EditorState.createEmpty(),
                        description: "",
                    }, () => {
                        navigate('/support/forum/view/?id=' + this.state.id, { state: { details: this.state.props, notification: true, notificationMessage: "Your comment has been successfully submitted!" } })
                        if (typeof window !== "undefined") {
                            window.location.reload()
                        }
                    })
                })
                .catch(err => console.log(err))
        } else {
            this.setState({
                isLoading: false,
                visible: true,
                errorMessage: "Invalid characters were detected in the comment you have submitted.",
                formValid: false
            })
        }

    }

    render () {

        if (typeof window !== 'undefined') {

            return (
                <div>
                    {/* ERROR NOTIFICATION */}
                    <Alert color="danger" toggle={this.onDismiss} isOpen={this.state.visible}>
                        Error while submitting your forum post : <br />
                        {this.state.errorMessage}
                    </Alert>
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Editor
                                editorState={this.state.editorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class form-control"
                                toolbarClassName="toolbar-class"
                                editorStyle={{
                                    backgroundColor: `white`,
                                    padding: `0.175rem 1rem`
                                }}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                        bold: { className: 'bordered-option-classname' },
                                        italic: { className: 'bordered-option-classname' },
                                        underline: { className: 'bordered-option-classname' },
                                        strikethrough: { className: 'bordered-option-classname' },
                                        code: { className: 'bordered-option-classname' },
                                    },
                                    blockType: {
                                        className: 'bordered-option-classname',
                                    },
                                    fontSize: {
                                        className: 'bordered-option-classname',
                                    },
                                    list: {
                                        inDropdown: true
                                    },
                                    textAlign: {
                                        inDropdown: true
                                    }
                                }}
                                onEditorStateChange={this.onEditorStateChange}
                            />
                        </FormGroup>

                        <FormGroup style={{ display: `flex`, justifyContent: `flex-end` }}>
                            <ReCAPTCHA
                                sitekey={process.env.CAPTCHA_SITEKEY}
                                onChange={this.captchaChange}
                                ref={(r) => this.recaptcha = r}
                            />
                        </FormGroup>

                        {/* Desktop Display */}
                        <div className="d-none d-md-block text-right">
                            <span>&nbsp;</span>

                            <Button
                                color={this.state.isLoading || !this.state.formValid ? 'secondary' : 'primary'}
                                disabled={this.state.isLoading || !this.state.formValid}
                            >
                                {this.state.isLoading ? <PulseLoader color="white" /> : 'Add Comment'}
                            </Button>
                        </div>

                        <br className="d-md-none" />

                        {/* Mobile Display */}
                        <Button
                            color={this.state.isLoading || !this.state.formValid ? 'secondary' : 'primary'}
                            disabled={this.state.isLoading || !this.state.formValid}
                            className="d-md-none"
                            block
                        >
                            {this.state.isLoading ? <PulseLoader color="white" /> : 'Add Comment'}
                        </Button>

                    </Form>
                </div>
            )


        } else {
            return (
                <div>
                    {/* ERROR NOTIFICATION */}
                    <Alert color="danger" toggle={this.onDismiss} isOpen={this.state.visible}>
                        Error while submitting your forum post : <br />
                        {this.state.errorMessage}
                    </Alert>
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>

                        </FormGroup>

                        <FormGroup style={{ display: `flex`, justifyContent: `flex-end` }}>
                            <ReCAPTCHA
                                sitekey={process.env.CAPTCHA_SITEKEY}
                                onChange={this.captchaChange}
                                ref={(r) => this.recaptcha = r}
                            />
                        </FormGroup>

                        {/* Desktop Display */}
                        <div className="d-none d-md-block text-right">
                            <span>&nbsp;</span>

                            <Button
                                color={this.state.isLoading || !this.state.formValid ? 'secondary' : 'primary'}
                                disabled={this.state.isLoading || !this.state.formValid}
                            >
                                {this.state.isLoading ? <PulseLoader color="white" /> : 'Add Comment'}
                            </Button>
                        </div>

                        <br className="d-md-none" />

                        {/* Mobile Display */}
                        <Button
                            color={this.state.isLoading || !this.state.formValid ? 'secondary' : 'primary'}
                            disabled={this.state.isLoading || !this.state.formValid}
                            className="d-md-none"
                            block
                        >
                            {this.state.isLoading ? <PulseLoader color="white" /> : 'Add Comment'}
                        </Button>

                    </Form>
                </div>
            )
        }


    }
}

export class ViewComments extends Component {

    render () {
        return (
            <CommentList data={this.props.content} />
        )
    }
}

const CommentList = (props) => {

    if (!props || (Object.keys(props).length === 0 && props.constructor === Object)) {
        return (
            <Col md={12} className="text-center" style={{ paddingBottom: `2rem` }}>
                No Comments for this post
            </Col>
        )
    } else {

        return props.data.map(function (value, index) {
            return (
                <ListGroupItem key={index}>
                    <ListGroupItemHeading className="clearfix">
                        <font className="float-left">
                            Comment by {value.author_name}
                        </font>
                        <font className="float-right">
                            {new Date(value.date_posted).toDateString()}
                        </font>
                    </ListGroupItemHeading>
                    <ListGroupItemText className="clearfix" tag="div" style={{ background: `#f7f7f9`, padding: `1rem` }}>
                        {ReactHtmlParser(value.content)}
                    </ListGroupItemText>
                </ListGroupItem>
            )
        })
    }
}

export class ViewContent extends Component {

    render () {

        const data = !this.props.data ? null : this.props.data[0]

        if (data === null) {
            return ''
        }
        return (
            <Card>
                <CardHeader className="clearfix">
                    <font className="float-left">
                        {data.title}
                    </font>
                    <font className="float-right">
                        #{data.post_id}
                    </font>
                </CardHeader>
                <CardBody>
                    <CardText tag="div">
                        {ReactHtmlParser(data.description)}
                    </CardText>
                </CardBody>
                <CardFooter className="clearfix">
                    <font className="float-left">
                        <AddTag data={data.tag} />
                    </font>
                    <font className="float-right">
                        {data.author_name}{` `}
                        (
                        {new Date(data.date_posted.split("T")[0]).toDateString()}
                        )
                    </font>
                </CardFooter>
            </Card>
        )
    }
}

export const AddTag = (props) => {

    var tagObject = JSON.parse(props.data)

    var tags = []
    tagObject.forEach(element => {
        tags.push(element.name)
    });


    if (!props.data || props.data === '') {
        return ''
    } else {
        // FILTER UNIQUE VALUES

        return tagObject.map(function (value, index) {
            return (
                <Badge
                    href="#"
                    style={{ marginRight: `3px` }}
                    key={index}
                    color="info"
                    onClick={
                        event => {
                            event.preventDefault()
                            navigate('/support/forum/#tags', { state: { tag: value } })
                        }
                    }
                >
                    #{value}
                    &nbsp;
                </Badge>
            )
        })
    }

}
